import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function CadastrarPremio() {
  const [enviando, setEnviando] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custoTotal, setCustoTotal] = useState('');
  const [tipo, setTipo] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');
  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState('');
  const [subCategoriaId, setSubCategoriaId] = useState('');
  const [showSubCategoria, setShowSubCategoria] = useState(false);

  const limparCampos = () => {
    setCodigo('');
    setNome('');
    setDescricao('');
    setTipo('');
    setFoto('');
    setPreview('');
    setCustoTotal('');
    setStatus('Procurar...');
    setCategoriaId('');
    setSubCategoriaId('');
    setShowSubCategoria(false);
  };

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const buscarCodigo = async (element) => {
    try {
      let codigo = element.target.value;
      if (!codigo) return;
      
      const resultado = await api.get(`/api/premios?codigo=${codigo}`);
      
      if (resultado?.data?.premios && resultado.data.premios.length > 0) {
        toast.error('Código já cadastrado', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setCodigo('');
      }
    } catch (error) {
      console.error('Erro ao verificar código:', error);
      toast.error('Erro ao verificar código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
  };

  const buscarCategorias = async () => {
    try {
      const response = await api.get('/api/premios/categorias?level=1');
      if (response?.data?.categorias) {
        setCategorias(response.data.categorias);
      }
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
      setCategorias([]);
    }
  };

  const buscarSubCategorias = async (categoriaId) => {
    try {
      const response = await api.get(`/api/premios/categorias?level=2&idCategoriaPai=${categoriaId}`);
      if (response?.data?.categorias) {
        setSubCategorias(response.data.categorias);
        setShowSubCategoria(true);
      }
    } catch (error) {
      console.error('Erro ao buscar subcategorias:', error);
      setSubCategorias([]);
      setShowSubCategoria(false);
    }
  };

  useEffect(() => {
    buscarCategorias();
  }, []);

  const cadastrarPremio = async () => {
    if (!nome) {
      toast.error('Informe o nome', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!codigo) {
      toast.error('Informe o código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!descricao) {
      toast.error('Informe a descrição', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!custoTotal) {
      toast.error('Informe o custo total', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!tipo) {
      toast.error('Informe o tipo', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!foto) {
      toast.error('Selecione uma foto', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!categoriaId) {
      toast.error('Selecione uma categoria', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);

    let formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('codigo', codigo);
    formData.append('custoTotal', custoTotal);
    formData.append('tipo', tipo === 'geral' ? null : tipo);
    formData.append('foto', foto);
    formData.append('codigoCategoria', subCategoriaId || categoriaId);

    const resultado = await api.post('/api/premios', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success('Prêmio cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      limparCampos();
    }
    setEnviando(false);
  };
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Prêmio</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form className="form" onSubmit={cadastrarPremio}>
          <label htmlFor="foto">Foto</label>
          <input
            type="file"
            id="foto"
            name="foto"
            style={{ display: 'none' }}
            onChange={alterarFoto}
          />
          <input
            className="text-left"
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('foto').click();
            }}
          />
          {preview && (
            <img alt="Preview" src={preview} className="mt-3 mb-3 w-100" />
          )}
          <label htmlFor="nome">Código</label>
          <Input
            id="codigo"
            name="codigo"
            type="text"
            placeholder="Código"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            onBlur={buscarCodigo}
          ></Input>
          <label htmlFor="nome">Nome</label>
          <Input
            id="nome"
            name="nome"
            type="text"
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          ></Input>
          <label htmlFor="descricao">Descrição</label>
          <textarea
            id="descricao"
            name="descricao"
            placeholder="Descrição"
            className="premio-desc p-2"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          ></textarea>
          <label htmlFor="custoTotal">Custo Total</label>
          <Input
            id="custoTotal"
            name="custoTotal"
            type="number"
            step="0.01"
            placeholder="Custo Total"
            value={custoTotal}
            onChange={(e) => setCustoTotal(e.target.value)}
          ></Input>
          <label htmlFor="tipo">Tipo</label>
          <select
            id="tipo"
            name="tipo"
            placeholder="Tipo"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
          >
            <option disabled value="">
              Selecione o tipo
            </option>
            <option value="geral">Geral</option>
            <option value="bivolt">Bivolt</option>
            <option value="credito">Crédito</option>
            <option value="recarga">Recarga</option>
          </select>
          <label htmlFor="categoria">Categoria</label>
          <select
            id="categoria"
            name="categoria"
            value={categoriaId}
            onChange={(e) => {
              const id = e.target.value;
              setCategoriaId(id);
              if (id) {
                buscarSubCategorias(id);
              } else {
                setShowSubCategoria(false);
                setSubCategorias([]);
              }
              setSubCategoriaId('');
            }}
          >
            <option value="">Selecione a categoria</option>
            {categorias.map((categoria) => (
              <option key={categoria.id} value={categoria.idCategoria}>
                {categoria.nome}
              </option>
            ))}
          </select>

          {showSubCategoria && (
            <>
              <label htmlFor="subcategoria">Sub-categoria</label>
              <select
                id="subcategoria"
                name="subcategoria"
                value={subCategoriaId}
                onChange={(e) => setSubCategoriaId(e.target.value)}
              >
                <option value="">Selecione a sub-categoria</option>
                {subCategorias.map((subcategoria) => (
                  <option key={subcategoria.id} value={subcategoria.idCategoria}>
                    {subcategoria.nome}
                  </option>
                ))}
              </select>
            </>
          )}

          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </Form>
      </div>
    </Container>
  );
}
